*{
  margin: 0;
  box-sizing: border-box;
}

.App {
  font-family: 'Montserrat Alternates';
  line-height: 1.6rem;
}

button:hover,
button:focus {
  box-shadow: inset 5px 5px 10px -3px rgba(54, 54, 54, 0.7);
}

button:active {
  box-shadow: inset 5px 5px 10px -3px rgba(54, 54, 54, 0.7);
}

a {
  text-decoration: none;
  color: white
}

strong {
  font-weight: inherit;
}

.Header-plein-menu .Active {
  border-bottom: 1px white solid;
}

.Header-menu .Active {
  text-decoration: underline;
}

h2 {
  text-align: left;
}

/*----- HEADER -----*/

.Header {
  width: 100%;
  min-height: 80px;
  background-color:  #063F3E;
  font-size: calc(1rem + 2vmin);
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Header-left {
  display: flex;
  align-items: center;
}

.Header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header img{
  width: 4rem;
  height: 4rem;
  margin: 0.5em;
}

.Header-title {
  font-size: 1.5rem;
}

.Header-plein-menu {
  display: none;
  font-size: 1.2rem;
  font-weight: bold;
}

.Header-plein-menu a {
  margin: 0 1em;
  border-bottom: 1px rgba(255, 255, 255, 0) solid;
}

.Header-burger {
  transform: rotate(90deg);
  font-weight: 500;
  letter-spacing: 0.1rem;
  cursor: pointer;
}

.Header-menu {
  margin-top: 4.45em;
  padding-top: 1em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.Header-menu a{
  border-bottom: #063F3E solid 1px;
  backdrop-filter: blur(5px);
  background-color: #c3ddd0a9;
  font-size: 1rem;
  padding: 0.6em;
  text-align: center;
  color:#063F3E;
  font-weight: bold;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.Header-button {
  display: none;
  font-weight: bold;
  font-size: 0.9rem;
}

@media(min-width: 800px){
  .Header-plein-menu {
    display: flex;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .Header-burger {
    display: none;
  }

  .Header-plein-menu a:hover {
    border-bottom: 1px white solid;
    cursor: pointer;
  }

  .Header-button {
    display: block;
    background-color: hsl(8, 37%, 53%);
    border: none;
    padding: 0.5em 1em;
    color: white;
    font-family: inherit;
    border-radius: 10px;
    width: 60%;
    cursor: pointer;
    max-width: 200px;
    width: fit-content;
  }
}

/*---- PRESENTATION COURTE -----*/

.Presentation {
  background-color: #eff3f1;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.Presentation-first-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
}

.Presentation h2 {
  margin-top: 1rem;
}

.Presentation p {
  padding: 1em 0;
}

.Presentation-detaillee{
  display: none;
}

@media(min-width: 700px){
  
  .Presentation-container {
    max-width: 1200px;
    margin: 2em auto;
  }

  .Presentation-first-container{
    margin: 2em 0;
    flex-direction: row;
  }

  .Presentation-generale{
    width: 45%;
  }

  .Presentation-container h2{
    margin: 1em 0;
  }

  .Presentation-second-container{
    display: block;
    margin: 1em 0;
  }

  .Presentation-detaillee {
    display: flex;
    justify-content: space-between;
  }

  .Presentation-detaillee p {
    margin-right: 2.5em;
    width: 50%;
  }

  .Presentation-detaillee p:last-child {
    margin-right: 0;
  }

}

/*---- ACTIVITE -----*/

.Activite {
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
}

.Activite p {
  padding: 1em 0;
}

.Activite h2 {
  margin-top: 1rem;
  text-align: center;
}

.Activite-more {
  background-color: #b36a5e;
  border: none;
  padding: 0.5em 1em;
  color: white;
  font-family: inherit;
  border-radius: 10px;
  width: 60%;
  cursor: pointer;
  max-width: 200px;
  width: fit-content;
  font-size: 1.05rem;
}

.Activite img {
  width: 50%;
}

.Activite img:last-child {
  width: 50%;
}

@media(min-width: 700px){
  .Activite-container{
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 2em auto;
  }
  .Activite {
    width: 50%;
    justify-content: space-between;
  }

  .Activite-more {
    font-size: 1rem;
  }

  .Activite-text {
    height: 350px;
    display: inline-block;
  }

  .Activite button {
    display: block;
  }
}

/*----- RESERVATION -----*/

.Reservation {
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eff3f1;
}

.Reservation button {
  background-color: #063F3E;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 0.8em 2em;
  font-family: inherit;
  font-size: 1.25rem;
  cursor: pointer;
}

/*---- FOOTER -----*/

.Footer {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #b36a5e;
  color: white;
}

.Footer div {
  margin-bottom: 1em;
}

.Socials {
  display: flex;
  align-items: center;
}

.Socials a {
  border: 1px #b36a5e solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 0;
}

.Socials a:hover,
.Socials a:focus {
  border: 1px white solid;
  border-radius: 5px;
}


@media(min-width: 700px){
  .Footer {
    flex-direction: row;
  }

  .Socials {
    margin-left: 2em;
  }
}